<template>

    <div class="dashboard-mobile">
        
        <Wallet @click="selectedTabIndex = 0" :isActive="selectedTabIndex === 0" />
        
        <div style='padding-right: 16px;'>
            <swiper ref="swiper" :options="swiperOptions" >
                <swiper-slide style="max-width: 80%; margin: 0 12px;">
                    <Chart @click="showChart('market-price')" :title="$t('dashboard.1-bitcoin')" name="market-price" :value="btc_market_price" variant="success" />
                </swiper-slide>
                <swiper-slide style="max-width: 80%; margin: 0 12px;">
                    <Chart @click="showChart('difficulty')" :title="$t('dashboard.complexity-network')" name="difficulty" :value="difficulty" variant="danger" />
                </swiper-slide>    
                <swiper-slide style="max-width: 80%; margin: 0 12px;">
                    <Chart @click="showChart('miners-revenue')" :title="$t('dashboard.mining-revenue')" name="miners-revenue" :value="miners_rewards" variant="warning" />
                </swiper-slide>     
            </swiper>
        </div>

        <RefferalCard v-if="isReferralCard && $user.self.is_referral" @close="isReferralCard = false" />
        
        <router-view />
        
    </div>
    
</template>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    
    import Wallet from "./widgets/wallet.mobile"
    import Chart from "./widgets/chart.desktop"
    import Contracts from "./widgets/contracts.mobile"
    
    import BitcoinChart from "./charts/Bitcoin"
    import DifficultyChart from "./charts/Difficulty"
    import MinersChart from "./charts/Miners"
    
    import Blockchain from "@/modules/blockchain"
    import User from "@/modules/user"
    import Charts from "@/modules/charts"
    
    import ContractsView from "./widgets/contracts.desktop"

    import RefferalCard from "./widgets/referralCard.mobile"

    export default {

        data() {
            return {
                Charts,
                Blockchain,
                selectedTabIndex: 0,
                swiperOptions: {
                    init: false,
                    slidesPerView: 'auto',
                    centeredSlides: false,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0
                },
                summary: {
                    cloud: {
                        total: 0,
                        current: 0,
                        ths: 0
                    },
                    miners: {
                        total: 0,
                        current: 0,
                        ths: 0
                    }
                },

                miners_rewards_val: 0,

                isReferralCard: true

            }
        },
        props: {
            news: Object,
            contracts: Object,
            offers: Object
        },
        methods: {
            showChart( name ) {
                this.$router.push({
                    name: "user-dashboard-chart",
                    params: {
                        name: name
                    }
                });
            },
        },
        components: {
            
            Swiper,
            SwiperSlide,
            
            ContractsView,
            
            Wallet,
            Chart,
            Contracts,
            
            BitcoinChart,
            DifficultyChart,
            MinersChart,
            RefferalCard
        },
        
        computed: {
            miners_rewards() {
                // if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                //     let data = Charts['miners-revenue'].series[0].data;
                //     let val = parseInt(data[ data.length - 1 ]);

                //     // let val = parseInt( Blockchain.stats.miners_revenue_usd );
                //     return val.toShort();
                // }

                if (this.miners_rewards_val == 0) return '0';

                return parseInt(this.miners_rewards_val).toShort();
            }, 
            
            difficulty() {
               let val = Blockchain.stats.difficulty ? Blockchain.stats.difficulty : 0 ;
               return val.toShort();
            }, 
            
            btc_market_price() {
                return (Blockchain.tickers.values[User.currency].last).formatMoney(2,' ', ' ' ) + ` ` + User.getSymbol();               
            }, 
        },
        
        watch: {
            "Charts" : function() {
                if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                    let data = Charts['miners-revenue'].series[0].data;

                    this.miners_rewards_val = data[ data.length - 1 ];
                }
            }
        },
        mounted() {
           setTimeout(() => {
                this.$refs.swiper.swiperInstance.init();

                if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                    let data = Charts['miners-revenue'].series[0].data;

                    this.miners_rewards_val = data[ data.length - 1 ];
                }
            }, 200 );
        }

    }

</script>