<template>
    <b-card v-ripple no-body class="ref_card">

        <b-card-body style="padding: 16px 0px 16px 16px;">
            <div class="d-flex">
                <div style="width: 100%">
                  
                    <div class="d-flex" style="">
                        <i18n path="referral.invite-friend-mobile" style="font-size: 20px; font-weight: 600; color:#FFF; line-height: 28px;" tag="p">
                            <br>
                            <br>
                        </i18n>
                    </div>

                    <div class="d-flex">
                        <i18n path="referral.award" style="font-size: 14px; color:#FFF; line-height: 20px;" tag="p">
                            <br>
                        </i18n>
                    </div>

                    <b-button :to="{ name: 'user-referral' }" variant="outline-primary" class="custom-light" style="width: 233px">{{$t('referral.btns.go-to')}}</b-button>
                </div>
                <div
                style="width: 100%;">
                      <a
                        data-action="close"
                        @click="$emit('close')"
                        class="close-ref-card"
                    >
                        <feather-icon
                            icon="XIcon"
                            size="18"
                            style="color: #FFF; margin-top: -2px"
                        />
                    </a>
                    <img src="@/assets/images/site/tab-elem-ref-card.png" alt="" style="width: 100%">
                </div>
            </div>

        </b-card-body>
    
    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    
    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
           
        },
        methods: {
        
        },
        
        computed: {
            
          
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss" scoped>

    .ref_card {
        background-image: url(~@/assets/images/site/back-ref-card.png);
        background-size: cover;
        background-repeat: no-repeat, repeat;
        background-position: center;
    }

    .custom-light {
        background-color: #FFF !important;
        color: #426FF6;
        border-color: #FFF !important;
        border-radius: 10px;
    }

    .close-ref-card {
        margin: auto auto auto 92%;
        svg {
            background-color: #ffffff42;
            padding: 1px;
            border-radius: 50px;
        }
    }

</style>