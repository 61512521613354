<template>
    <b-card @click="fireClickEvent" v-ripple no-body :bg-variant="isActive ? '' : 'transparent' " :class=" isActive ? 'is-picker' : 'is-picker with-border'">

        <b-card-body style="padding: 24px;">
            <p style="margin-bottom: 6px;"> {{$t('dashboard.wallet.balance')}} <b-link @click="changeCurrency">{{$t('dashboard.wallet.in')}} {{ User.currency }}</b-link> </p>

            <div class="d-flex">
                <!-- <p style="font-size: 28px; font-weight: 600;">{{ total_profit.formatMoney( User.currency === "BTC" ? 4 : 0,' ', ' ') }} {{ User.getSymbol() }}</p> -->
                <p style="font-size: 28px; font-weight: 600;">{{ currency_balance }} {{ ticker }}</p>
            </div>

            <div>
                
                <span class="block-view">
                    {{ last_profit.formatMoney(User.currency === "BTC" ? 4 : 2,' ', ' ') }} {{ ticker }} <span class="text-muted" style="text-transform:lowercase;">{{$t('statistics.profit24')}}</span>
                </span>
                <div style="padding: 6px;"></div>
                <span class="block-view">
                    {{ total_ths }} TH/s <span class="text-muted" style="text-transform:lowercase;">{{$t('statistics.total-capacity')}}</span>
                </span>
                
            </div>
        
            <b-button-group class="d-flex" style="margin-top: 22px;">
                <b-button :to="{ name: 'user-wallet' }" variant="primary" style="font-weight: 400;  padding: 16px 20px; line-height: 14px; height: 48px; font-size: 14px; border-radius: 6px; margin-right: 8px; border: none !important;">{{$t('wallet.in-wallet')}}</b-button>
                <b-button :to="{ name: 'user-wallet', query: { act: 'output' } }" variant="gray" style="font-weight: 400; height: 48px;  padding: 16px 20px; line-height: 14px; font-size: 14px; border-radius: 6px; margin-left: 8px; border: none !important;">{{$t('wallet.withdraw')}}</b-button>
            </b-button-group>
        
        </b-card-body>
    
        

    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    
    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
            isActive: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            fireClickEvent( args ) {
                this.$emit( "click", args );
            },
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
        },
        
        computed: {
            
            ticker() {
               return User.getSymbol();
            },
            
            total_ths() {
                
               if( this.$route.name === "user-contracts-list") {
                    return this.user.stats.cloud.total_ths.formatMoney(2," ", " ");       
               }
               
               if( this.$route.name === "user-miners-list") {
                   return this.user.stats.miners.total_ths.formatMoney(2," ", " ");       
               }
                
               return (this.user.stats.miners.total_ths + this.user.stats.cloud.total_ths).formatMoney(2," ", " ");
               
            }, 
            
            last_profit() {
                
                if( this.$route.name === "user-contracts-list") {
                    return this.user.stats.cloud['24h_profit'] * Blockchain.tickers.values[User.currency].last;   
                }
               
                if( this.$route.name === "user-miners-list") {
                   return this.user.stats.miners['24h_profit'] * Blockchain.tickers.values[User.currency].last;   
                }
                                
                return ( this.user.stats.miners['24h_profit'] + this.user.stats.cloud['24h_profit'] ) * Blockchain.tickers.values[User.currency].last;    
                
            },
           
            total_profit() {
                
               if( this.$route.name === "user-contracts-list") {
                    return this.user.stats.cloud['total_profit'] * Blockchain.tickers.values[User.currency].last;   
                }
               
                if( this.$route.name === "user-miners-list") {
                   return this.user.stats.miners['total_profit'] * Blockchain.tickers.values[User.currency].last;   
                }
                                
                return ( this.user.stats.miners['total_profit'] + this.user.stats.cloud['total_profit'] ) * Blockchain.tickers.values[User.currency].last;     
               
            },
            
            user() {
               return User.self;
            },

            balance() {
                return (this.user.balance + this.user.wallet.balance);
            },

            currency_balance() {
                return (Blockchain.tickers.values[User.currency].last * this.balance).formatMoney(User.currency === "BTC" ? 6 : 0,' ', ' ');
            }
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>