<template>
    <b-card @click="fireClickEvent" v-ripple no-body :bg-variant="isActive ? '' : 'transparent' " :class=" isActive ? 'is-picker' : 'is-picker with-border' ">
        <b-card-body style="position: relative; padding: 20px 0 16px 24px">
            <div style="z-index: 100; position: relative;">
                <div>
                    <span class="text-muted" style="font-weight: 300; font-size: 14px;">{{ title }}</span>
                    <p style="font-size: 22px; font-weight: 600; margin-top: 4px;">
                        <span>{{ value }}</span>
                    </p>
                </div>
            </div>
            <div style="position: absolute; top: 0%; right: 0; left: 60%; max-height: 100px; overflow: hidden; mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1));">
                <statistic-card-with-area-chart
                    v-if="Charts.is_can_show"
                    :color="variant"
                    statistic="123"
                    icon="123"
                    :chart-data="Charts[name].series"
                />

            </div>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import Charts from "@/modules/charts"
    import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

    export default {

        data() {
            return {
                Charts
            }
        },
        props: {
            isActive: {
                type: Boolean,
                default : false
            },
            title: {
                type: String
            },
            name: {
                type: String,
                required: true
            },
            variant: {
                type: String,
                default: "success"
            },
            value: {
                type: String,
                default: '0'
            }
        },
        methods: {
            fireClickEvent( args ) {
                this.$emit( "click", args );
            }
        },
        components: {
            StatisticCardWithAreaChart
        },
        watch: {

        },
        mounted() {

        }

    }

</script>