<template>

    <div class="d-flex">
        
        <div style="width: 272px;">
            <div >
                <Wallet @click="$router.push({ name: 'user-dashboard' })" :isActive=" !$route.params.name" />
                <Chart @click="showChart('market-price')" :isActive=" $route.params.name === 'market-price'" :title="$t('dashboard.1-bitcoin')" name="market-price" :value="btc_market_price" variant="success" />
                <Chart @click="showChart('difficulty')" :isActive=" $route.params.name === 'difficulty'" :title="$t('dashboard.complexity-network')" name="difficulty" :value="difficulty" variant="danger" />
                <Chart @click="showChart('miners-revenue')" :isActive="$route.params.name === 'miners-revenue'" :title="$t('dashboard.mining-revenue')" name="miners-revenue" :value="miners_rewards" variant="warning" />

                <b-button v-if="special.banners.length > 0" :href="top_errors_pdf" target="_blank" variant="outline-primary" block style="height: 44px; margin-top: 24px; margin-bottom: 24px; font-size: 14px; border-radius: 10px; padding-top: 14px; border-width: 2px !important;">{{$t('pick-up-bonus')}}</b-button>

                <RefferalCard v-if="isReferralCard && $user.self.is_referral" @close="isReferralCard = false" />

            </div>
        </div>
        
        <div style="width: calc(100% - 272px); padding-left: 32px;">
            <transition name='zoom-fade'>
                <router-view />
            </transition>
        </div>

    </div>
    
</template>

<script>
    
    import Wallet from "./widgets/wallet.desktop"
    import Chart from "./widgets/chart.desktop"
    import Charts from "@/modules/charts"

    import Contracts from "./widgets/contracts.desktop"
    
    import BitcoinChart from "./charts/Bitcoin"
    import DifficultyChart from "./charts/Difficulty"
    import MinersChart from "./charts/Miners"
    
    import Blockchain from "@/modules/blockchain"
    import User from "@/modules/user"

    import RefferalCard from "./widgets/referralCard.desktop"
    import special from "@/modules/contents/special"

    export default {

        data() {
            return {
                special,
                Blockchain,
                Charts,
                selectedTabIndex: 0,
                miners_rewards_val: 0,

                isReferralCard: true
            }
        },
       
        methods: {
            showChart( name ) {
                this.$router.push({
                    name: "user-dashboard-chart-desktop",
                    params: {
                        name: name
                    }
                });
            },
        },
        components: {
            Wallet,
            Chart,
            Contracts,
            
            BitcoinChart,
            DifficultyChart,
            MinersChart,
            RefferalCard
        },
        
        computed: {
            miners_rewards() {
                // if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                //     let data = Charts['miners-revenue'].series[0].data;
                //     let val = parseInt(data[ data.length - 1 ]);

                //     // let val = parseInt( Blockchain.stats.miners_revenue_usd );
                //     return val.toShort();
                // }

                if (this.miners_rewards_val == 0) return '0';

                return parseInt(this.miners_rewards_val).toShort();
            }, 
            
            difficulty() {
               let val = Blockchain.stats.difficulty ? Blockchain.stats.difficulty : 0 ;
               return val.toShort();
            }, 
            
            btc_market_price() {
                return (Blockchain.tickers.values[User.currency].last).formatMoney(2,' ', ' ' ) + ` ` + User.getSymbol();               
            }, 

            top_errors_pdf() {
                return `${process.env.VUE_APP_BASE_URL}toperrors/pdf`;
            }
        },
        
        watch: {
            "Charts" : function() {
                if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                    let data = Charts['miners-revenue'].series[0].data;

                    this.miners_rewards_val = data[ data.length - 1 ];
                }
            }
        },
        mounted() {
            setTimeout(() => {
                if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                    let data = Charts['miners-revenue'].series[0].data;

                    this.miners_rewards_val = data[ data.length - 1 ];
                }
            }, 200 );

        },

    }

</script>