<template>
    <b-card no-body class="m-0">        
        <b-card-body>
            
            <b-card-title style="font-size: 20px; font-weight: 600; font-style: normal;">Контракты</b-card-title> 
            
            <b-row>

                <b-col cols="12" class="d-flex justify-content-between">
                    
                    <b-button-group>
                        <template v-for="(status, index) in statuses">
                            <b-button  @click="setStatus(status.value)" :variant="filter.status === status.value ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ status.display }}</b-button>
                            <div v-if="index < statuses.length - 1" class="delimiter"></div>
                        </template>
                    </b-button-group> 

                </b-col>
                
            </b-row>
            
            <b-row v-if="statistic.length > 0" class="mt-2 block-view" style="border-radius: 10px; padding: 12px;">
                <b-col>
                    <p>Самый доходный контракт <b-link :to="{ name: 'user-contract-details', params: { id: statistic[0].guuid } } ">{{ statistic[0].guuid }}</b-link></p>
                    <b-progress :max="100">
                        <b-progress-bar v-for="(item,index) in statistic" label="" :value="item.percent" :style="`background-color: ${colors[index]}`"></b-progress-bar>
                    </b-progress>
                </b-col>
            </b-row>
            
        </b-card-body>
       

        <b-skeleton-table v-if='is_loading' animation="fade" :columns='5' :rows='5'>
            
        </b-skeleton-table>

        <b-card-body class='d-flex flex-column' v-else-if='contracts.items.length === 0 && !filter.status' >
            <b-card-text class='text-center font-medium-2 mt-2'>
                У Вас еще нет созданных контрактов.
            </b-card-text>
            <b-button  :to="{name: 'user-contracts-create'}" pill class='ml-auto mr-auto mt-2' variant='primary'>Создать первый контракт</b-button>
        </b-card-body>


        <b-table-simple v-else responsive >
            <b-thead>
                <b-tr>
                    <b-th>№</b-th>
                    <b-th>Добыто</b-th>
                    <b-th>Мощность</b-th>
                    <b-th>Статус</b-th>
                    <b-th>Прогресс</b-th>
                </b-tr>
            </b-thead>

            <b-tbody>
                <b-tr v-for="(item, index) in contracts.items" :key="`contract:${item.id}:${index}`">
                    <b-th>
                        <b-link :to="{ name: 'user-contract-details', params: { id: item.guuid } } "> {{ item.guuid }}</b-link>
                    </b-th>
                    <b-th class="font-weight-normal text-black">{{ getTotalProfit(item.total_balance) }} <span class="text-muted">{{ ticker }}</span> </b-th>
                    <b-th class="font-weight-normal text-black">{{ item.ths.formatMoney(2,' ', ' ') }} <span class="text-muted">TH/s</span> </b-th>
                    <b-th class="font-weight-normal text-black">
                        <b-badge v-if="item.status == 1">Работает</b-badge>
                        <b-badge v-else-if="item.status == 0" variant="danger">Требуется оплата</b-badge>
                        <b-badge v-else variant="success">Завершён</b-badge>
                    </b-th>
                    <b-th class="font-weight-normal text-black">
                        
                        <div class="d-flex justify-content-between">
                            <b-progress
                                style="flex-grow: 1; margin: auto 0;"
                                :value="getCompletePercent(item)"
                                max="100"
                                height="12px"
                                variant="primary"
                            />
                            
                            <span class="text-muted mt-auto mb-auto" style="font-size: 12px; margin-left: 3px;">
                                {{ getCompletePercent(item).toFixed(1) }}%
                            </span>
                            
                        </div>
                        
                    </b-th>
                </b-tr>
            </b-tbody>

        </b-table-simple>

<!--        <b-card-body v-if="contracts.total_count > 0" class="p-0 d-flex">
            <div class="ml-auto mr-auto">
                 <b-pagination-nav :value="contracts.current_page" :link-gen="linkGen" :number-of-pages="contracts.last_page" use-router replace ></b-pagination-nav>
            </div>
        </b-card-body>-->
        
    </b-card>
</template>

<script>
    
    import User from "@/modules/user"
    import Blockchain from "@/modules/blockchain";

    export default {

        data() {
            return {
                
                is_loading: false,
                
                filter: {
                    status: 'inwork'
                },
                
                contracts: {
                    current_page: 0,
                    has_more_pages: false,
                    last_page: 0,
                    total_count: 0,
                    items: []
                },
                
                statuses: [
                    { value: null, display: "Все" },
                    { value: "inwork", display: "В работе" },
                    { value: "nopay", display: "Неоплаченные" },
                    { value: "complete", display: "Выполненые" }
                ],
                
                statistic: [],
                
                colors: [ '#46BCAA', '#FFCF52', '#4D69FA', '#F35421', '#F19C99' ],
                
                fields: [
                    { key: "guuid", label: "NO" },
                    { key: "ths", label: "Мощность" },
                    { key: "total_balance", label: "Заработок" },
                    { key: "is_active", label: "Статус" },
                    { key: "progress", label: "" },
                ],
                
                
            }
        },
        methods: {
            
            setStatus( value ) {
                this.filter.status = value;
            },
            
            getCompletePercent( contract ) {
                return ((contract.total_balance * Blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD( contract )) * 100;     
            },
            
            getPeriodProfitUSD( contract ) {
                return (((84600 * 30 * contract.period * User.contracts.values.R * ( User.contracts.values.H * contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * Blockchain.tickers.values["USD"].last) ;                
            },

            getTotalProfit( value ) {
                return ( value * Blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
            },
            
            get() {
                
                this.is_loading = true;
                
                this.$request.get("contracts.getList", this.params ).then( result => {
                    
                    this.contracts.current_page = result.current_page;
                    this.contracts.has_more_pages = result.has_more_pages;
                    this.contracts.last_page = result.last_page;
                    this.contracts.total_count = result.total_count;
                    this.contracts.items = result.items;
                    
                    this.is_loading = false;
                    
                });                
            },
            
            
            handleScroll( e ) {
                               
                               
                if(!this.contracts) return;
                if(!this.contracts.has_more_pages) return;
                if( this.is_loading || this.is_loading_more_items ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.contracts.current_page + 1;
                  
                  this.is_loading_more_items = true;
                  
                  this.$request.get("contracts.getList", params ).then( result => {
                       this.contracts.has_more_pages = result.has_more_pages;
                       this.contracts.current_page = result.current_page;
                       this.contracts.items.push( ...result.items );
                       this.is_loading_more_items = false;                       
                  });
                  
                }
                
            },
            
            
            getStatistic() {
                this.$request.get("contracts.getSimpleStat").then( result => {
                    this.statistic = result;
                });
            }
        },
        
        created() {
            document.getElementsByClassName('content-wrapper')[0].addEventListener("scroll", this.handleScroll );
        },
        
        destroyed() {
            document.getElementsByClassName('content-wrapper')[0].removeEventListener("scroll", this.handleScroll );
        },
        
        computed: {
            
            ticker() {
                return User.getSymbol();
            },
            
            params() {
                return {
                    status: this.filter.status
                }
            }
        },
        
        components: {

        },
        watch: {
            "filter.status" : function() {
                this.get();
            }
        },
        mounted() {
            
            this.get();
            this.getStatistic(); 
            
        }

    }

</script>