<template>
    <b-card v-if="summary" no-body style="padding: 0 12px;">
        <b-card-header class="d-flex align-items-center" >
            <b-card-title @click="goToContracts" style="font-size: 20px; font-weight: 500; cursor: pointer;">
                Облако <feather-icon icon="ChevronRightIcon" size="17" style="margin-top: -2px;"/>
            </b-card-title>
            <div @click=" $router.push({ name: 'user-contracts-create' }) " style="color: var(--theme-color); cursor: pointer; font-weight: 500; font-size: 15px;">
               <feather-icon icon="PlusCircleIcon" size="17" style="margin-top: -2px;"/> Арендовать
            </div>
        </b-card-header>
        <b-card-body style="padding: 0px 32px 16px 32px;">
             <b-row class="form-group-with-label" style="padding: 16px; border-radius: 16px;">
                <b-col>
                    <span class="text-muted">Добыто всего</span>
                    <p class="m-0 font-weight-bolder">{{ (summary.total * Blockchain.tickers.values[User.currency].last).formatMoney(1," "," ") }} {{ ticker }}</p>
                </b-col>
                <div class="delimiter" style="max-width: 1px;"></div>
                <b-col>
                    <span class="text-muted">Добыто чистыми</span>
                    <p class="m-0 font-weight-bolder">{{ (summary.current * Blockchain.tickers.values[User.currency].last).formatMoney(1," "," ") }} {{ ticker }}</p>
                </b-col>
                <div class="delimiter" style="max-width: 1px;"></div>
                <b-col>
                    <span class="text-muted">Суммарная мощность</span>
                    <p class="m-0 font-weight-bolder">{{ summary.ths.formatMoney(2," "," ") }} TH/s</p>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"

    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
            summary: Object
        },
        methods: {
            goToContracts( args ) {
                this.$router.push({ name: "user-contracts-list" });
            }
        },
        computed: {
            
            ticker() {
               return User.getSymbol();
            },
          
            
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>