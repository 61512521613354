<template>
    <b-card v-if="news.items.length > 0" bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex align-items-center">
                
                <div style="margin-right: 8px; cursor: pointer;">
                    Новости <feather-icon icon="ChevronRightIcon" size="17" style="margin-top: -2px;"/> 
                </div>
                
            </b-card-title>
        </b-card-header>
        
        <b-card-body style="padding: 0">
            <swiper :options="swiperOptions" >
                <swiper-slide v-for="item in news.items">
                    <news-view :item='item' />
                </swiper-slide>
            </swiper>
        </b-card-body>
        
    </b-card>
</template>

<script>
    
    import NewsView from "@/components/NewsView"
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {

        data() {
            return {
                news: null,
                swiperOptions: {
                    slidesPerView: 2,
                    slidesOffsetBefore: 0
                },
            }
        },
      
        methods: {
            get() {
                this.$request.get("news.list").then( rsp => this.news = rsp );
            }
        },
        components: {
            NewsView,
            Swiper,
            SwiperSlide
        },
        watch: {

        },
        mounted() {
            this.get();
        }

    }

</script>