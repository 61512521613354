<template>
    
    <div>
        
        <b-card-title class="section-title" style="padding-left: 16px; padding-right: 16px;">
            Контракты
        </b-card-title>
        
        <div style="overflow-y: scroll; padding-left: 16px; padding-right: 16px;">
            <b-button-group style="white-space: nowrap; margin-bottom: 24px;">
                <template v-for="(status, index) in statuses">
                    <b-button  @click="setStatus(status.value)" :variant="filter.status === status.value ? 'active-white' : 'inactive' " size="sm" style="height: 36px;">{{ status.display }}</b-button>
                    <div v-if="index < statuses.length - 1" class="delimiter"></div>
                </template>
            </b-button-group> 
        </div>
        
        <b-card no-body v-if="statistic.length > 0" style="margin-left: 16px; margin-right: 16px;">
            <b-card-body style="padding: 12px 20px;">
                <b-row>
                    <b-col>
                        <p>Самый доходный контракт <b-link>{{ statistic[0].guuid }}</b-link></p>
                        <b-progress :max="100">
                            <b-progress-bar v-for="(item,index) in statistic" label="" :value="item.percent" :style="`background-color: ${colors[index]}`"></b-progress-bar>
                        </b-progress>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        
        <div v-if="contracts" style="padding-left: 16px; padding-right: 16px;">
            <div v-if="contracts.items.length > 0">
                <b-card v-for="(item, index) in contracts.items" :key="index" no-body @click="openContract(item)" style="margin-top: 8px; margin-bottom: 6px;">

                    <b-card-header class="pb-0">

                        <b-card-title style="font-weight: normal; font-size: 16px;">
                            {{ item.guuid }}
                        </b-card-title>

                        <b-badge v-if="item.status == 1">Работает</b-badge>
                        <b-badge v-else-if="item.status == 0" variant="danger">Требуется оплата</b-badge>
                        <b-badge v-else variant="success">Завершён</b-badge>

                    </b-card-header>

                    <b-card-body class="pt-0" style="padding: 20px;">
                        <b-list-group flush class="no-hover">
                            <b-list-group-item style="padding: 8px 0;">
                                <div class="d-flex justify-content-between">
                                    <span class="text-muted text-uppercase mt-auto mb-auto" style="font-size: 12px;">Добыто</span>
                                    <span>{{ getTotalProfit(item.total_balance) }} {{ ticker }}</span>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item style="padding: 8px 0;">
                                <div class="d-flex justify-content-between">
                                    <span class="text-muted text-uppercase mt-auto mb-auto" style="font-size: 12px;">Мощность</span>
                                    <span>{{ item.ths.formatMoney(2,' ', ' ') }} TH/s</span>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item style="padding: 8px 0;">
                                <div class="d-flex justify-content-between">
                                    <span class="text-muted text-uppercase mt-auto mb-auto" style="font-size: 12px;">Прогресс</span>
                                    <div class="d-flex justify-content-between">
                                        <b-progress
                                            style="flex-grow: 1; margin: auto 0; min-width: 50px;"
                                            :value="getCompletePercent(item)"
                                            max="100"
                                            height="12px"
                                            variant="primary"
                                        />

                                        <span class="text-muted mt-auto mb-auto" style="font-size: 12px; margin-left: 3px;">
                                            {{ getCompletePercent(item).toFixed(1) }}%
                                        </span>

                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>

                </b-card>
            </div>
        </div>
        <div v-else>
            
        </div>
    
        
    </div>   
        
</template>

<script>
    
    import User from "@/modules/user"
    import Blockchain from "@/modules/blockchain";

    export default {

        data() {
            return {
                
                is_loading: false,
                
                filter: {
                    status: 'inwork'
                },
                
                contracts: {
                    current_page: 0,
                    has_more_pages: false,
                    last_page: 0,
                    total_count: 0,
                    items: []
                },
                
                statuses: [
                    { value: null, display: "Все" },
                    { value: "inwork", display: "В работе" },
                    { value: "nopay", display: "Неоплаченные" },
                    { value: "complete", display: "Выполненые" }
                ],
                
                statistic: [],
                
                colors: [ '#46BCAA', '#FFCF52', '#4D69FA', '#F35421', '#F19C99' ],
                
                fields: [
                    { key: "guuid", label: "NO" },
                    { key: "ths", label: "Мощность" },
                    { key: "total_balance", label: "Заработок" },
                    { key: "is_active", label: "Статус" },
                    { key: "progress", label: "" },
                ],
                
                
            }
        },
        methods: {
            
            openContract(item) {
                this.$router.push({ 
                    name: 'user-contract-details', 
                    params: { 
                        id: item.guuid 
                    } 
                });
            },
            
            setStatus( value ) {
                this.filter.status = value;
            },
            
            getCompletePercent( contract ) {
                return ((contract.total_balance * Blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD( contract )) * 100;     
            },
            
            getPeriodProfitUSD( contract ) {
                return (((84600 * 30 * contract.period * User.contracts.values.R * ( User.contracts.values.H * contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * Blockchain.tickers.values["USD"].last) ;                
            },

            getTotalProfit( value ) {
                return ( value * Blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
            },
            
            get() {
                
                this.is_loading = true;
                
                this.$request.get("contracts.getList", this.params ).then( result => {
                    
                    this.contracts.current_page = result.current_page;
                    this.contracts.has_more_pages = result.has_more_pages;
                    this.contracts.last_page = result.last_page;
                    this.contracts.total_count = result.total_count;
                    this.contracts.items = result.items;
                    
                    this.is_loading = false;
                    
                });                
            },
            
            
            handleScroll( e ) {
                               
                               
                if(!this.contracts) return;
                if(!this.contracts.has_more_pages) return;
                if( this.is_loading || this.is_loading_more_items ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.contracts.current_page + 1;
                  
                  this.is_loading_more_items = true;
                  
                  this.$request.get("contracts.getList", params ).then( result => {
                       this.contracts.has_more_pages = result.has_more_pages;
                       this.contracts.current_page = result.current_page;
                       this.contracts.items.push( ...result.items );
                       this.is_loading_more_items = false;                       
                  });
                  
                }
                
            },
            
            
            getStatistic() {
                this.$request.get("contracts.getSimpleStat").then( result => {
                    this.statistic = result;
                });
            }
        },
        
        created() {
            document.getElementsByClassName('content-wrapper')[0].addEventListener("scroll", this.handleScroll );
        },
        
        destroyed() {
            document.getElementsByClassName('content-wrapper')[0].removeEventListener("scroll", this.handleScroll );
        },
        
        computed: {
            
            ticker() {
                return User.getSymbol();
            },
            
            params() {
                return {
                    status: this.filter.status
                }
            }
        },
        
        components: {

        },
        watch: {
            "filter.status" : function() {
                this.get();
            }
        },
        mounted() {
            
            this.get();
            this.getStatistic(); 
            
        }

    }

</script>