<template>
    <b-card no-body>
     
        
        <b-card-body class='pb-0 mb-0'>
            
            <b-card-title style="font-size: 20px; font-weight: 600; font-style: normal;">
               Доход майнеров
            </b-card-title> 
            
            <b-row>
                <b-col>
                    <p>
                        <span class="text-black" style="font-size: 22px;">{{ miners_rewards.formatMoney(0,' ', ' ') }} USD </span>
                        <span v-if="market_price_percent" :class=" market_price_percent > 0 ? 'text-success' : 'text-danger' " style="font-size: 14px;">
                            (<span v-if="market_price_percent > 0">+</span>{{ market_price_percent }}%)
                        </span>
                    </p>
                </b-col>
            </b-row>
            
            <b-row>

                <b-col cols="12" class="d-flex justify-content-between">
                    <div class="d-flex tab-selection">
                        <div :class=" Charts.filters['miners-revenue'].period === '1week' ? 'active' : '' " @click="setPeriod('1week')">1н</div>
                        <div :class=" Charts.filters['miners-revenue'].period === '1months' ? 'active' : '' " @click="setPeriod('1months')">1м</div>
                        <div :class=" Charts.filters['miners-revenue'].period === '3months' ? 'active' : '' " @click="setPeriod('3months')">3м</div>
                        <div :class=" Charts.filters['miners-revenue'].period === '6months' ? 'active' : '' " @click="setPeriod('6months')">6м</div>
                        <div :class=" Charts.filters['miners-revenue'].period === '1year' ? 'active' : '' " @click="setPeriod('1year')">1г</div>
                        <div :class=" Charts.filters['miners-revenue'].period === '5year' ? 'active' : '' " @click="setPeriod('5year')">5л</div>
                    </div>
                </b-col>
                
            </b-row>
            
        </b-card-body>
        
        <vue-apex-charts
            type="area"
            height="auto"
            width="100%"
            :options="chartOptions"
            :series="Charts['miners-revenue'].series"
        />
        
    </b-card>
</template>

<script>

    import Charts from "@/modules/charts"
    import { $themeColors } from '@themeConfig'
    import VueApexCharts from 'vue-apexcharts'
    import Blockchain from "@/modules/blockchain"

    var chartLabels = [];
    
    export default {

        data() {
            return {
                
                Charts,
                is_can_show_graphic: true,
                
                market_price_percent: null,
                market_price_diff: null,
                
                chartOptions: {
                    chart: {
                        animations: {
                             enabled: true
                        },
                        toolbar: {
                          show: false,
                        },
                        sparkline: {
                          enabled: false,
                        },
                        zoom: {
                            enabled: true,
                            type: 'x',
                            autoScaleYaxis: false
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: "#EBECF2",
                        strokeDashArray: 3,
                        position: 'front',
                        column: {
                            colors: [ "#FFF" ],
                            opacity: 0.1
                        },
                        row: {
                            colors: [ "#EBECF2" ],
                            opacity: 0.1
                        },
                        padding: {
                          left: 10,
                          right: 0,
                        },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                        width: 2,
                        curve: 'smooth',
                    },
                    theme: { 
                        monochrome: {
                            enabled: false,
                            color: $themeColors.warning,
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.9,
                            opacityFrom: 0.5,
                            opacityTo: 0.2,
                            stops: [0, 80, 100],
                            gradientToColors: [ $themeColors.warning ],
                        },
                    },
                    colors: [ $themeColors.danger ],
                    xaxis: {
                        categories: Charts['miners-revenue'].labels,
                        type: 'category',
                        labels: {
                            show: true,
                            rotate: -10,
                            trim: true,
                            style: {
                                fontSize: "8px"
                            },
                            formatter: ( value ) => {
                                
                                if( !value ) return "";
                                var date = new Date(value *  1000);
                                var day = new Intl.DateTimeFormat('ru', {  day: 'numeric' }).format(date);
                                var formatted = new Intl.DateTimeFormat('ru', {  year: '2-digit', month: 'numeric' }).format(date);
                                                                                                
                                return formatted;
                                
                            },
                            format: 'dd/MM'
                        },
                    },
                    yaxis: {
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: true,
                            formatter: ( value ) => {
                                return value.formatMoney(0,' ', ' ')
                            }
                        }
                    },
                    tooltip: {
                        x: { 
                            show: false 
                        },
                        y: {
                            title: {
                                formatter: (seriesName) => "$"
                            },
                        },
                    },
                },
                
            }
        },
        methods: {
            setPeriod( value ) {
                
                  Charts.filters['miners-revenue'].period = value;
                  
                  Charts.update('miners-revenue').then( result => {
                      
                      let firstValue = result[0].y;
                      let lastValue = result[ result.length - 1 ].y;
                      
                      this.market_price_percent = ((( lastValue - firstValue ) / firstValue) * 100).toFixed(2);
                      this.market_price_diff = lastValue - firstValue;
                      
                  });
                  
            },
        },
        
        computed: {
            miners_rewards() {
                let data = Charts['miners-revenue'].series[0].data;
                return parseInt(data[ data.length - 1 ]);

                // return parseInt( Blockchain.stats.miners_revenue_usd );
           },            
        },
        
        components: {
            VueApexCharts
        },
        watch: {
            
        },
        mounted() {
            
            let data = Charts['miners-revenue'].series[0].data;

            let firstValue = data[0];
            let lastValue = data[ data.length - 1 ];

            
            this.market_price_percent = ((( lastValue  ) / firstValue) * 100).toFixed(2);
            this.market_price_diff = lastValue - firstValue;
                
        }

    }

</script>