<template>

    <div>
        
        <div class="d-flex match-height">

            <div style="width: 50%; padding-right: 12px;">
                <Wallet @click="$router.push({ name: 'user-dashboard' })" :isActive=" !$route.params.name" />
            </div>
            
            <div class="d-flex flex-column" style="width: 50%; padding-left: 12px;">
                <Chart @click="showChart('market-price')" :isActive=" $route.params.name === 'market-price'" :title="$t('dashboard.1-bitcoin')" name="market-price" :value="btc_market_price" variant="success" />
                <Chart @click="showChart('difficulty')" :isActive=" $route.params.name === 'difficulty'" :title="$t('dashboard.complexity-network')" name="difficulty" :value="difficulty" variant="danger" />
            </div>


        </div>   

        <RefferalCard v-if="isReferralCard && $user.self.is_referral" @close="isReferralCard = false" />
        
        <transition name='zoom-fade'>
            <router-view />
        </transition>
        
    </div>
    
</template>

<script>
    
    import Wallet from "./widgets/wallet.tablet"
    import Chart from "./widgets/chart.desktop"
    import Contracts from "./widgets/contracts.tablet"
    
    import BitcoinChart from "./charts/Bitcoin"
    import DifficultyChart from "./charts/Difficulty"
    import MinersChart from "./charts/Miners"
    
    import Blockchain from "@/modules/blockchain"
    import User from "@/modules/user"

    import RefferalCard from "./widgets/referralCard.tablet"

    export default {

        data() {
            return {
                Blockchain,
                selectedTabIndex: 0,
                isReferralCard: true

            }
        },
        props: {
            news: Object,
            contracts: Object,
            offers: Object
        },
        methods: {
            showChart( name ) {
                this.$router.push({
                    name: "user-dashboard-chart-desktop",
                    params: {
                        name: name
                    }
                });
            },

        },
        components: {
            Wallet,
            Chart,
            Contracts,
            
            BitcoinChart,
            DifficultyChart,
            MinersChart,
            RefferalCard
        },
        
        computed: {
            miners_rewards() {
                if (Charts['miners-revenue'] && Charts['miners-revenue'].hasOwnProperty('series')) {
                    let data = Charts['miners-revenue'].series[0].data;
                    let val = parseInt(data[ data.length - 1 ]);

                    // let val = parseInt( Blockchain.stats.miners_revenue_usd );
                    return val.toShort();
                }
            }, 
            
            difficulty() {
               let val = Blockchain.stats.difficulty ? Blockchain.stats.difficulty : 0 ;
               return val.toShort();
            }, 
            
            btc_market_price() {
                return (Blockchain.tickers.values[User.currency].last).formatMoney(2,' ', ' ' ) + ` ` + User.getSymbol();               
            }, 
        },
        
        watch: {

        },
        mounted() {
            
        }

    }

</script>