<template>

    <b-card bg-variant="transparent" no-body>
        
        <b-card-header>
            <b-card-title class="d-flex align-items-center">
                
                <div style="margin-right: 8px;">Предложения для вас</div>
                
                <countdown :end-time="new Date().getTime() + (60000 * 321)">
                    
                    <template
                      v-slot:process="countdown">
                        <div class="d-flex counter">
                            <div>{{ countdown.timeObj.h }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.m }}</div>
                                <span class="text-muted">:</span>
                            <div>{{ countdown.timeObj.s }}</div>
                        </div>
                    </template>
            
                </countdown>
                
                <div class="text-muted" style="font-size: 12px; font-weight: 400; margin-left: 6px;">
                    действует<br>предложение 
                </div>
                
            </b-card-title>
        </b-card-header>
        
        
        <b-card-body style="padding: 0">
            <swiper :options="swiperOptions" >
                <swiper-slide v-for="(i, index) in 10" :key="index">
                    <b-card no-body style=" margin: 0 12px;">
                        <b-card-body style="padding: 16px;">
                            <b-card-title style="font-size: 20px; padding: 0; margin: 0">
                                Старт <span style="color: var(--theme-color)">15 TH/s </span> 
                            <b-img
                                src="~@/assets/images/icons/emoji/fire.png"
                                fluid
                                alt="bonus"
                                style="width: 20px; height: 20px;"
                            ></b-img>
                            </b-card-title>
                            <div class="delimiter" style="width: 100%; height: 1px; margin: 12px 0;"></div>
                            <div style="font-size: 12px;">
                                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                                    <span class="text-muted font-weight-light" >
                                        Ежедневная добыча
                                    </span>
                                    <span>
                                       4,24 $
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                                    <span class="text-muted font-weight-light">
                                        Мощность контракта
                                    </span>
                                    <span>
                                       15 TH/s
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                                    <span class="text-muted font-weight-light">
                                        Плата за электроэнергию
                                    </span>
                                    <span>
                                       1,045 $
                                    </span>
                                </div>
                                <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                                    <span class="text-muted font-weight-light">
                                        Срок контракта
                                    </span>
                                    <span>
                                       980 дней
                                    </span>
                                </div>
                            </div>
                            <div class="delimiter" style="width: 100%; height: 1px; margin: 12px 0;"></div>
                            
                            <div class="d-flex justify-content-between" style="margin: 8px 0; font-size: 12px;">
                                <span class="text-muted font-weight-light">
                                    Стоимость<br>контракта
                                </span>
                                <span style="font-size: 26px; font-weight: 500;">
                                   1 200 $
                                </span>
                            </div>
                            
                            <div class="d-flex justify-content-end">
                                <b-badge variant="success" style="background-color: #3ED085; color: #FFF;">5 дней бесплатно</b-badge>
                            </div>
                            
                            <b-button size="md" variant="gray" outline style="margin-top: 22px; width: 100%; padding: 18px 28px; border-radius: 16px;">
                                Подключить контракт
                            </b-button>
                            
                        </b-card-body>
                    </b-card>
                </swiper-slide>
            </swiper>
        </b-card-body>
        
    </b-card>
    
</template>

<style>
    
    .counter {
        align-items: center;
    }
    
    .counter div {
        margin: 0 2px;
        color: #FFF;
        background-color: var(--theme-color);
        width: 27px; 
        height: 27px;
        text-align: center;
        line-height: 27px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
       
    }
</style>

<script>
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    
    export default {

        data() {
            return {
                swiperOptions: {
                    slidesPerView: 3,
                    slidesOffsetBefore: 0
                },
            }
        },
        methods: {

        },
        components: {
            Swiper,
            SwiperSlide
        },
        watch: {

        },
        mounted() {
            this.$router.get("contracts.getOffers").then( rsp => {
                
            });
        }

    }

</script>